<template>
  <div class="p-grid">
    <Card
      v-for="cardItem in roles"
      :key="cardItem.name"
      class="p-mx-4 p-mb-4 p-p-6 p-col-5"
    >
      <template slot="title">
        <div class="role-title">
          {{ cardItem.name }}
        </div>
      </template>
      <template slot="subtitle">
        <div class="role-description">
          {{ cardItem.description }}
        </div>
      </template>
      <template slot="content">
        <div class="role-item" v-for="(item, index) in roleItems">
          <div class="role-item-text">
            {{ item }}
          </div>
          <div class="role-item-value p-d-flex p-justify-center">
            <div
              class="dot p-d-flex p-ai-center p-justify-center dot-true"
              v-if="cardItem.items[index]"
            >
              <i class="pi pi-check"></i>
            </div>
            <div
              class="dot p-d-flex p-ai-center p-justify-center dot-false"
              v-else
            >
              <i class="pi pi-times"></i>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UserRoles extends Vue {
  public roles = [
    {
      name: "User",
      description: "Default role with minimal viewing permissions",
      items: [true, false, true, false, false, false, false, false]
    },
    {
      name: "Manager",
      description: "Talent Managers with account edit access",
      items: [true, false, true, true, false, false, true, false]
    },
    {
      name: "Super",
      description: "Talent Managers with elevated access",
      items: [true, false, true, true, true, false, true, true]
    },
    {
      name: "Alpha",
      description: "Senior staff and developers",
      items: [true, true, true, true, true, true, true, true]
    }
  ];

  public roleItems = [
    "View generic overview widgets",
    "View revenue charts",
    "View all data in Manage and Mastersheet sections",
    "Add/edit Creators",
    "Add/edit Manager Groups",
    "Add/edit Managers",
    "Add/edit Accounts",
    "Access to Export section"
  ];
}
</script>

<style scoped>
.role-title {
  text-transform: uppercase;
  color: #22a7f0;
  text-align: center;
}

.role-description {
  text-align: center;
  margin-bottom: 1rem;
  height: 3rem;
}

.role-item {
  margin-bottom: 1.5rem;
}

.role-item-text {
  text-align: center;
  color: purple;
  margin-bottom: 0.5rem;
}

.role-item-value {
  text-align: center;
}

.dot {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: inline-block;
  color: white;
}

.dot-true {
  background-color: #00e640;
}

.dot-false {
  background-color: #cf000f;
}

.dot i {
  font-size: 0.75rem;
  font-weight: bold;
}
</style>
